enum EnumMediaType {
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
}

const getFileExtensionsPerMediaType = (mediaType: EnumMediaType): string[] => {
  const mappings = {
    IMAGE: ['jpg', 'jpeg', 'png', 'svg', 'gif'],
    DOCUMENT: ['pdf'],
    AUDIO: ['mp3', 'wav'],
    VIDEO: ['mp4'],
  };

  return mappings[mediaType] || [];
};

const getFileExtension = (fileName: string): string => (fileName.split('.').pop()?.toLowerCase() || '');

export { getFileExtensionsPerMediaType, getFileExtension };
