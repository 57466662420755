
import { Options, Vue } from 'vue-class-component';
import pdf from 'vue3-pdf';
import ApiCalls from '@/services/api-calls';
import { Media } from '@/models';
import { getFileExtension, getFileExtensionsPerMediaType } from '@/helpers/media';
import store from '@/store';

@Options({
  components: { pdf },
})
export default class Navbar extends Vue {
  api = new ApiCalls();

  mediaItem: Media | null = null;

  ontologyNames: string[] = [];

  labelMappings = [];

  getFileExtension = getFileExtension;

  getFileExtensionsPerMediaType = getFileExtensionsPerMediaType;

  async created(): Promise<void> {
    this.ontologyNames = store.getters.getRegisteredVocabs;

    this.mediaItem = this.$route.params.media
      ? JSON.parse(this.$route.params.media as string)
      : await this.api.getMedia(parseInt(this.$route.params.id as string, 10)).then((response) => ({
        id: parseInt(this.$route.params.id as string, 10),
        itemId: response['o:item']['o:id'],
        title: response['o:title'] || '',
        fileName: response['o:filename'] || '',
        url: response['o:original_url'] || '',
        resourceTemplateId: response['o:resource_template'] ? response['o:resource_template']['o:id'] : null,
        imageSquare: response.thumbnail_display_urls.square.split('?')[0],
        imageMedium: response.thumbnail_display_urls.medium.split('?')[0],
        imageLarge: response.thumbnail_display_urls.large.split('?')[0],
        properties: Object.keys(response).filter((x) => this.ontologyNames.some((y) => x.startsWith(y))).map((x) => ({
          id: response[x][0].property_id,
          key: response[x][0].property_label,
          // value: response[x][0]['@value'],
          value: response[x],
        })),
      }));

    const resourceTemplateResponse = await this.api.getResourceTemplateById(this.mediaItem!.resourceTemplateId!);
    this.labelMappings = resourceTemplateResponse['o:resource_template_property'].map((x: any) => ({ propertyId: x['o:property']['o:id'], label: x['o:alternate_label'] }));
  }
}
